import {
  add,
  addMonths,
  endOfYear,
  format,
  isAfter,
  isBefore,
  isDate,
  isFuture,
  isToday,
  isValid,
  parseISO,
  sub,
  subMonths,
} from 'date-fns'
import { CONTRACT_START_DATE, DATE_FORMAT_ISO } from '@/config/constants'

export const DateTimeUtil = {
  formatDate(input, dateFormat) {
    if (!input) return

    if (dateFormat) {
      return format(DateTimeUtil.parseDate(input), dateFormat)

      // force output to locale
    } else {
      return new Date(input).toLocaleDateString()
    }
  },

  /**
   * @param {Number} hour
   * @param {Number} minute
   * @param {String} separator
   * @returns {String}
   */
  getTimeString(hour, minute = 0, separator = ':') {
    if (hour < 0) {
      hour = 0
    }
    if (minute < 0) {
      minute = 0
    }
    const time = [hour < 10 ? '0' + hour : hour.toString(), minute < 10 ? '0' + minute : minute.toString()]
    return time.join(separator)
  },

  getAgeEndOfContractStartYear(dateOfBirth, contractStartDate) {
    if (!dateOfBirth || !contractStartDate) throw new Error('Missing parameters')
    const endOfContractStartYear = this.formatDate(endOfYear(parseISO(contractStartDate)), DATE_FORMAT_ISO)
    return this.getAge(dateOfBirth, endOfContractStartYear)
  },

  /**
   * return the age
   * @param  {String} input A birthdate formatted like this yyyy-MM-dd
   * @return {Number}
   */
  getAge(input, today = false) {
    if (!DateTimeUtil.isValidBirthDate(input)) {
      return null
    }

    const birthDate = parseISO(input)
    today = !today ? new Date() : parseISO(today)

    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  },

  getAdults(persons) {
    return persons.filter(person => DateTimeUtil.getAge(person.dateOfBirth) >= 18)
  },

  /**
   * @param time
   * @returns {number}
   */
  getIntegerTime(time) {
    return parseInt(time?.split(':')[0] ?? 0)
  },

  /**
   *
   * @param date A date formatted like this yyyy-MM-dd
   * @returns {String}
   */
  getDayBefore(date) {
    return this.formatDate(sub(this.parseDate(date), { days: 1 }), DATE_FORMAT_ISO)
  },

  getDefaultMinDate() {
    return this.formatDate(sub(new Date(), { years: 150 }), DATE_FORMAT_ISO)
  },

  getDefaultMaxDate() {
    return this.formatDate(add(new Date(), { months: 18 }), DATE_FORMAT_ISO)
  },

  getMinContractStartDate(date = new Date()) {
    return this.formatDate(subMonths(date, CONTRACT_START_DATE.MAX_MONTHS_PAST), DATE_FORMAT_ISO)
  },

  getMaxContractStartDate(date = new Date()) {
    return this.formatDate(addMonths(date, CONTRACT_START_DATE.MAX_MONTHS_FUTURE), DATE_FORMAT_ISO)
  },

  getContractStartDateOptions(dates) {
    const list = []

    dates?.forEach(startDate => {
      const dateValue = this.formatDate(startDate, DATE_FORMAT_ISO)
      const dateText = this.formatDate(startDate)
      list.push({ value: dateValue, label: dateText })
    })

    return list
  },

  getYearRange(past = 10, future = 2) {
    const data = []
    const now = new Date().getFullYear()

    for (let i = now + 1; i <= now + future; i++) {
      data.push({ value: i, label: i })
    }
    data.reverse()

    for (let i = now; i >= now - past; i--) {
      data.push({ value: i, label: i })
    }

    return data
  },

  isBirthday(date) {
    const year = new Date().getFullYear()
    return isToday(this.parseDate(`${year}-${date.substring(5)}`))
  },

  isInFuture(date) {
    const _date = this.parseDate(date)
    return isFuture(_date)
  },

  isValidDate(value) {
    if (!value || value.length !== 10) return false
    const date = this.parseDate(value)
    return isValid(date)
  },

  isValidBirthDate(value) {
    const isValidDate = DateTimeUtil.isValidDate(value)
    if (!isValidDate) return false

    const date = this.parseDate(value)
    const today = new Date()
    const future = add(today, { months: 9 })
    const past = sub(today, { years: 150 })

    return isAfter(date, past) && isBefore(date, future)
  },

  parseDate(input) {
    if (!input) return
    if (isDate(input)) return input

    if (input.includes('.')) {
      input = `${input.substring(6, 10)}-${input.substring(3, 5)}-${input.substring(0, 2)}`
    }

    return parseISO(input)
  },

  simplifyTime(time) {
    if (time) {
      const items = time.split(':')
      return [items[0], items[1]].join(':')
    }
  },

  createDummyDate(date, time) {
    const closesAt = time.split(':')
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), closesAt[0], closesAt[1], 0, 0)
  },

  simplifyTimes(data) {
    const holidays = data.holidays.map(holiday => {
      return {
        holiday,
        from: this.simplifyTime(holiday.from),
        to: this.simplifyTime(holiday.to),
      }
    })
    return {
      ...data,
      holidays,
      from: this.simplifyTime(data.from),
      to: this.simplifyTime(data.to),
    }
  },
}
