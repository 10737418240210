import { ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { NOTIFICATION } from '@/config/constants'
import callbackToError from '@/config/callbackToError'

import useI18n from '@/hooks/useI18n'

// GLOBAL DATA
const duration = 7000
const snacks = ref([]) // Queue

export default function useSnackbar() {
  // HOOKS
  const { t, te } = useI18n()

  // METHODS
  function add(notification) {
    snacks.value.push(notification)
    return notification
  }

  function addNotification(notification = {}) {
    if (!notification.id) notification.id = uuidv4()
    if (!notification.type) notification.type = NOTIFICATION.INFO
    notification.type = notification.type.toLowerCase()

    if (notification.key) {
      let vars = {}
      if (callbackToError[notification.key]?.vars) {
        vars = callbackToError[notification.key]?.vars(notification.payload)
      }

      if (te(notification.key)) {
        notification.text = t(notification.key, vars)
      } else if (te(`error.${notification.key}`)) {
        notification.text = t(`error.${notification.key}`, { ...vars, personFullName: notification.personFullName })
      } else {
        notification.text = t('error.GENERAL', vars)
      }
    }

    if (callbackToError[notification.key]) {
      notification.action = {
        label: t(callbackToError[notification.key].label),
        callback: () => callbackToError[notification.key].callback(notification.personId),
      }
    }

    if (
      notification.persistent !== false &&
      (notification.persistent ||
        notification.action ||
        [NOTIFICATION.WARNING, NOTIFICATION.ERROR].includes(notification.type))
    ) {
      notification.persistent = true
    } else {
      removeNotification(notification.id, duration)
    }

    return add(notification)
  }

  function removeNotification(snackId, delay = 0) {
    window.setTimeout(() => {
      const index = snacks.value.findIndex(s => s.id === snackId)

      if (index > -1) {
        snacks.value.splice(index, 1)
      }
    }, delay)
  }

  function removeAllNotifications() {
    snacks.value.splice(0, snacks.value.length)
  }

  return {
    addNotification,
    removeNotification,
    removeAllNotifications,

    snacks,
  }
}
