import events$ from '@/services/Events'

import usePerson from '@/hooks/usePerson'

import { DateTimeUtil } from '@/utils/DateTime'

import { EVENT_FAB, EVENT_URL } from '@/config/events'
import { ROUTE_NAME } from '@/config/constants'

function getPersonId() {
  return window.location.hash.substring(11, 47)
}

function getContractStartDatesOfPerson(payload) {
  const person = payload.persons.find(p => p.personId === getPersonId())

  return {
    kvgContractStartDate: DateTimeUtil.formatDate(person?.kvgContractStartDate),
    vvgContractStartDate: DateTimeUtil.formatDate(person?.vvgContractStartDate),
  }
}

function getContractDurationOfPerson() {
  const { getPerson } = usePerson()

  const person = getPerson()

  // @todo: in case we don't know which person. BE needs to tell us first, who it concerns...
  if (!person) return { duration: 'basket' }

  const productId = ['SSOB01Produkt', 'SSOB02Produkt'].find(__productId => {
    return person.products.products[__productId].selected
  })

  return {
    duration: person.products.products[productId].prices.find(p => p.selected).duration,
  }
}

function getContractStartDatesOfUnborn(payload) {
  const person = payload.persons.find(p => p.personData.gender === 'UNBORN')
  return {
    kvgContractStartDate: DateTimeUtil.formatDate(person?.kvgContractStartDate),
    vvgContractStartDate: DateTimeUtil.formatDate(person?.vvgContractStartDate),
  }
}

const mapping = {
  ['screen.basket.existingCustomer.noChanges']: {
    label: 'product.toOffer',
    callback: () => {
      events$.emit(EVENT_URL.GOTO, {
        type: 'push',
        route: {
          name: ROUTE_NAME.OFFERS_ALT,
        },
      })
    },
  },
  ['BASKET_ID_NOT_AVAILABLE']: {
    label: 'entry.restart',
    callback: () => {
      window.location.reload()
    },
  },
  ['BASKET_UPDATE_NO_LONGER_POSSIBLE']: {
    label: 'fab.contactUs',
    callback: () => {
      events$.emit(EVENT_FAB.OPEN)
    },
  },
  ['CAPITAL_ILLNESS_MINIMUM_ONE_OTHER_PRODUCT_REQUIRED']: {
    label: 'product.toOffer',
    callback: personId => {
      events$.emit(EVENT_URL.GOTO, {
        type: 'push',
        route: {
          name: ROUTE_NAME.PRODUCT_CATEGORY_COLLECTIVE,
          params: {
            personId,
            categoryId: 'SCPC_ADDITIONAL', // Redirect to missing product combination by categoryId for a specific person
          },
        },
      })
    },
  },
  ['CONTRACT_DURATION_SET_SAME_AS_PARENT']: {
    label: 'basket.gotoBasket',
    vars: getContractDurationOfPerson,
    callback: () => {
      events$.emit(EVENT_URL.GOTO, {
        type: 'push',
        route: {
          name: ROUTE_NAME.BASKET,
          path: 'basket/',
        },
      })
    },
  },
  ['CONTRACT_OWNER_BIRTH_DATE_INVALID']: {
    label: 'screen.adminData.contractOwner.changeContractOwner',
    callback: () => {
      events$.emit(EVENT_URL.GOTO, {
        type: 'push',
        route: {
          name: ROUTE_NAME.APPLICATION_ADMIN_DATA,
          path: 'application/administrative-data/',
        },
      })
    },
  },
  ['CONTRACT_START_DATE_CHANGED']: {
    label: 'basket.gotoBasket',
    vars: getContractStartDatesOfPerson,
    callback: () => {
      events$.emit(EVENT_URL.GOTO, {
        type: 'push',
        route: {
          name: ROUTE_NAME.BASKET,
          path: 'basket/',
        },
      })
    },
  },
  ['CONTRACT_START_DATE_CHANGED_DATE_OF_BIRTH_WITHIN_3_MONTHS']: {
    label: 'basket.gotoBasket',
    vars: getContractStartDatesOfPerson,
    callback: () => {
      events$.emit(EVENT_URL.GOTO, {
        type: 'push',
        route: {
          name: ROUTE_NAME.BASKET,
          path: 'basket/',
        },
      })
    },
  },
  ['CONTRACT_START_DATE_CHANGED_UNBORN']: {
    label: 'basket.gotoBasket',
    vars: getContractStartDatesOfUnborn,
    callback: () => {
      events$.emit(EVENT_URL.GOTO, {
        type: 'push',
        route: {
          name: ROUTE_NAME.BASKET,
          path: 'basket/',
        },
      })
    },
  },
  ['INVALID_CONTRACT_OWNER_REMOVED']: {
    label: 'basket.toApplicationAsExistingCustomer',
    callback: () => {
      events$.emit(EVENT_URL.GOTO, {
        type: 'push',
        route: {
          name: ROUTE_NAME.APPLICATION_ADMIN_DATA,
          path: 'application/administrative-data/',
        },
      })
    },
  },
  ['INVALID_DOCTOR_REMOVED']: {
    label: 'screen.basket.toDoctorSelection',
  },
  ['INVALID_PRODUCT_COMBINATION_AVAILABLE_IN_BASKET']: {
    label: 'product.toOffer',
    callback: personId => {
      events$.emit(EVENT_URL.GOTO, {
        type: 'push',
        route: {
          name: ROUTE_NAME.PRODUCT_CATEGORY,
          params: {
            personId,
            categoryId: 'ADDITIONAL', // Redirect to missing product combination by categoryId for a specific person
          },
        },
      })
    },
  },
  ['INVALID_PRODUCT_REMOVED_FROM_SELECT']: {
    label: 'product.toOffer',
    callback: personId => {
      events$.emit(EVENT_URL.GOTO, {
        type: 'push',
        route: {
          name: ROUTE_NAME.OFFERS,
          params: { personId },
        },
      })
    },
  },
  ['INVALID_REQUEST_FORMAT']: {
    label: 'entry.restart',
    callback: () => {
      window.location.reload()
    },
  },
  ['MEDICAL_PRIVATE_MINIMUM_ONE_OTHER_VVG_HOSPITAL_OR_AMBULANT_PRODUCT_REQUIRED']: {
    label: 'product.toOffer',
    callback: () => {
      events$.emit(EVENT_URL.GOTO, {
        type: 'push',
        route: {
          name: ROUTE_NAME.OFFERS_ALT,
        },
      })
    },
  },
  ['PERSON_WITHOUT_PRODUCT_IN_BASKET']: {
    label: 'product.toOffer',
    callback: personId => {
      events$.emit(EVENT_URL.GOTO, {
        type: 'push',
        route: {
          name: ROUTE_NAME.OFFERS,
          params: { personId },
        },
      })
    },
  },
  ['UVG_KVG_PRODUCT_ACCIDENT_CHANGED']: {
    label: 'basket.gotoBasket',
    callback: () => {
      events$.emit(EVENT_URL.GOTO, {
        type: 'push',
        route: {
          name: ROUTE_NAME.BASKET,
          path: 'basket/',
        },
      })
    },
  },
}

export default mapping
