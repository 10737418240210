import { datadogRum } from '@datadog/browser-rum'
import { version, zone } from '../../public/info.json'

export const init = router => {
  const applicationId = import.meta.env.VITE_DATADOG_APPLICATION_ID
  const clientToken = import.meta.env.VITE_DATADOG_CLIENT_TOKEN
  const isEnabled = import.meta.env.VITE_DATADOG_ENABLED
  const service = import.meta.env.VITE_DATADOG_SERVICE
  const sessionSampleRate = import.meta.env.VITE_DATADOG_SESSION_SAMPLE_RATE
  const sessionReplaySampleRate = import.meta.env.VITE_DATADOG_SESSION_REPLAY_SAMPLE_RATE
  const trackUserInteractions = import.meta.env.VITE_DATADOG_TRACK_USER_INTERACTIONS
  const trackResources = import.meta.env.VITE_DATADOG_TRACK_RESOURCES
  const trackLongTasks = import.meta.env.VITE_DATADOG_TRACK_LONG_TASKS
  const apiBaseUrl = import.meta.env.VITE_SANITAS_MICROSERVICE_URI

  if (isEnabled && applicationId && clientToken) {
    // Datadog RUM docs => https://docs.datadoghq.com/real_user_monitoring/browser
    datadogRum.init({
      applicationId,
      clientToken,

      // Do not change this, it belongs to our organization and will always be "eu".
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',

      // "service" is defined in our datadog terraform project
      // => https://gitlab.sanet17.ch/infrastructure/as-code/datadog/-/blob/master/rum.tf?ref_type=heads
      service,

      // Do not change - Set by the pipeline
      env: zone || 'local',

      // Do not change - Set by the pipeline - Same as the branch name
      // eslint-disable-next-line no-undef
      version,

      sessionSampleRate: Number(sessionSampleRate),
      sessionReplaySampleRate: Number(sessionReplaySampleRate),

      // Beware, enabling "trackUserInteractions" will output the textual content of all HTML elements
      // the user interacts with, you should absolutely set "enablePrivacyForActionName" to true if you
      // enable it.
      trackUserInteractions: Boolean(trackUserInteractions),

      trackResources: Boolean(trackResources),
      trackLongTasks: Boolean(trackLongTasks),

      // Keep "mask" as otherwise sensitive data might end up on the Datadog servers
      defaultPrivacyLevel: 'mask',

      // Always keep "enablePrivacyForActionName" to true if you enable "trackUserInteractions"
      enablePrivacyForActionName: true, // check if we can set it to false without showing sensitive data

      // Enable "trackViewsManually" to avoid page load to be counted many times due to the multiple
      // redirects between keycloak and the SPA. You need to explicitely trigger the "View Load" event
      // in your router via datadogRum.startView(<ViewName>)
      // trackViewsManually: false, // check if this is needed as datadog works with SPA out of the box

      allowedTracingUrls: [apiBaseUrl],

      // Here we could suppress known errors or add meta-data needed for tracking
      // beforeSend: _event => {
      //  console.log('SEND MORE STUFF')
      // },
    })

    router.beforeEach(to => {
      try {
        datadogRum.startView(to.name?.toString())
      } catch (e) {
        console.error('Could not start DD view', e)
      }
    })
  }
}

export const setUserUid = userUid => {
  datadogRum.setUser({ id: userUid })
}
