import basketStore from '@/store/basket'
import useBasket from '@/hooks/useBasket'

import { logInfo } from '@/helpers/Logger'

export default function useContentsquare() {
  // HOOKS
  const { hasImmigratedPerson } = useBasket()

  function triggerCSPageEvent(value) {
    window._uxa = window._uxa || []
    window._uxa.push(['trackPageEvent', value])

    logInfo(['%ctrackPageEvent', 'color: yellow', value])
  }

  function triggerCSPageView() {
    window._uxa = window._uxa || []

    sendCSDynamicVariables()

    const value = window.location.pathname + window.location.hash.replace('#', '?__')

    window._uxa.push(['trackPageview', value])
    logInfo(['%ctrackPageview', 'color: yellow', value])
  }

  function sendCSDynamicVariables() {
    const url = new URL(window.location.href)

    window._uxa.push(['trackDynamicVariable', { key: 'basketId', value: basketStore.basket.basketId }])
    window._uxa.push(['trackDynamicVariable', { key: 'cmpId', value: url.searchParams.get('cmpID') }])
    window._uxa.push(['trackDynamicVariable', { key: 'persons', value: basketStore.basket.persons?.length || 0 }])
    window._uxa.push([
      'trackDynamicVariable',
      { key: 'immigrant', value: hasImmigratedPerson(basketStore.basket) ? 'true' : 'false' },
    ])
    window._uxa.push([
      'trackDynamicVariable',
      { key: 'customer', value: basketStore.basket.existingCustomer ? 'true' : 'false' },
    ])
  }

  return {
    triggerCSPageEvent,
    triggerCSPageView,
  }
}
