import { computed } from 'vue'
import Axios from 'axios'

import sessionStore from '@/store/session'

import { NOTIFICATION } from '@/config/constants'
import { events$ } from '@/services'
import { EVENT_OVP, SOURCE } from '@/config/events'

// GLOBAL DATA
// eslint-disable-next-line no-undef
const npmVersion = __NPM_VERSION__

export default function useBackend() {
  // LOCAL DATA
  const _config = {
    baseUrl: null,
    axios: null,
  }

  // COMPUTED
  // @todo fix naming, get as prefix is confusing for a computed value
  const getBaseUrl = computed(() => _config.baseUrl)

  // METHODS
  function buildURL(path, options) {
    return _config.axios.getUri({ url: path, params: options })
  }

  function setup(baseUrl, service, interceptor) {
    _config.baseUrl = constructUrl(service, baseUrl)
    _config.axios = Axios.create({
      baseURL: _config.baseUrl,
      timeout: 180000, // 3min timeout for backend requests
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        v: npmVersion,
      },
    })

    // Add jwtoken to request-header if available in session
    _config.axios.interceptors.request.use(function (config) {
      if (sessionStore.session.jwToken) {
        config.headers['X-Softlogin-Token'] = sessionStore.session.jwToken
      }

      return config
    })

    _config.axios.interceptors.response.use(
      function (response) {
        if (response?.data === undefined) {
          window.ineum('reportError', `Invalid api response: ${response}`)
        }
        const messages = mapResponseMessages(response)
        emitResponseMessages({ messages, payload: response?.data?.data })
        return response.data
      },
      async function (error) {
        if (isInfrastructureError(error)) {
          error.response.data = {
            infoMessages: [{ errorCode: 'UPSTREAM_IS_FUCKED' }],
          }
        }

        const isErrorPassthrough =
          error.config?.passthrough === true ||
          error.config?.passthrough?.includes(error.response.data.infoMessages[0]?.errorCode)

        if (!isErrorPassthrough) {
          const messages = mapResponseMessages(error.response)
          emitResponseMessages({ messages, level: NOTIFICATION.ERROR, payload: error.response?.data?.data })
        }

        const errorResponse = await createErrorResponse(error)
        return Promise.reject(errorResponse)
      }
    )

    if (interceptor) _setInterceptor(interceptor)
  }

  /**
   * isInfrastructureError returns true if the response does not contain an expected
   * payload (either a success or error), but instead returns an html-response containing
   * 'Wartungsarbeiten'
   *
   * @param {AxiosError} error
   * @returns {boolean}
   */
  function isInfrastructureError(error) {
    return (
      error?.response?.data !== undefined &&
      typeof error.response.data === 'string' &&
      error.response.data.indexOf('<!DOCTYPE html') !== -1 &&
      error.response.data.indexOf('Wartungsarbeiten') !== -1
    )
  }

  /**
   *  convertBlobToJson takes a blop and converts it to a json object
   *  @param {Blob} blobData
   *  @returns {object}
   */
  async function convertBlobToJson(blobData) {
    return await new Response(blobData).json()
  }

  /**
   * Construct an URL from the given parts.
   *
   * @param {string} base
   * @param {string} extension
   * @private
   */
  function constructUrl(base, extension) {
    if (!base) return extension
    if (!extension) return base

    // Prevent accidential double dashes
    return base.replace(/\/+$/, '') + '/' + extension.replace(/^\/+/, '')
  }

  /**
   *  createErrorResponse takes an axios error and transforms it into a dvp error object
   *  @param {object} error
   *  @returns {ErrorResponse}
   */
  async function createErrorResponse(error) {
    if (error.config.responseType === 'blob') {
      error.response.data = await convertBlobToJson(error.response.data)
    }

    return {
      data: error?.response?.data?.data,
      messages: mapResponseMessages(error?.response),
      source: SOURCE.BACKEND,
      status: error?.response?.status ?? 999,
    }
  }

  /**
   * Set the interceptor that will receive all responses before they are returned to the original caller.
   */
  function _setInterceptor({ responseSuccess, responseError }) {
    _config.axios.interceptors.response.use(responseSuccess, responseError)
  }

  /**
   * Send a fetch request with an optional JSON body and return a JSON response.
   *
   * @param {string} endpoint URL extension of the service method.
   * @param {Object} options Optional object with request options.
   * @param {string} options.method  HTTP method to use for this request. Default: GET
   * @param {Object} options.payload JSON payload that is sent with the request.
   * @param {Object} options.headers optional headers to use.
   * @return {Promise} A promise that will be resolved with the response body parsed as JSON.
   *
   */
  function _call(options, passthrough = []) {
    return _config.axios({ ...options, passthrough })
  }

  /**
   * Shortcut function to perform a delete request.
   * @param {*} url URL extension of the service method.
   * @param {*} data JSON payload
   */
  function doDelete({ url, passthrough = [] }) {
    return _call(
      {
        method: 'DELETE',
        url,
      },
      passthrough
    )
  }

  /**
   * Shortcut function to perform a get call.
   * @param {string} url URL extension of the service method.
   * @param {Object} params for the query string
   * @param {Boolean=} noCache Force reload from server
   */
  function doGet({ url, params, noCache = false, options = {}, passthrough = [] }) {
    const baseOptions = {
      method: 'GET',
      url,
      params,
    }

    if (noCache) {
      baseOptions.headers = {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      }
    }

    options = Object.assign({}, baseOptions, options)
    return _call(options, passthrough)
  }

  /**
   * Shortcut function to perform a post request.
   * @param {*} url URL extension of the service method.
   * @param {*} data JSON payload
   * @param config additional config (headers, listeners etc)
   */
  function doPost({ url, data, config = {}, passthrough = [] }) {
    return _call(
      {
        method: 'POST',
        url,
        data,
        ...config,
      },
      passthrough
    )
  }

  /**
   * Shortcut function to perform a put request.
   * @param {*} url URL extension of the service method.
   * @param {*} data JSON payload
   */
  function doPut({ url, data, passthrough = [] }) {
    return _call(
      {
        method: 'PUT',
        url,
        data,
      },
      passthrough
    )
  }

  /**
   *  mapResponseMessages maps messages coming from an api response to a format to display in the UI.
   *  There are two different message structs in the api response:
   *  1. infoMessages - Array with objects containing both the errorCode as well as the errorLevel
   *  2. errorCode - string containing only the code
   *  3. errorMessage - string containing person Id
   *
   *  @returns {APIMessage[]}
   */
  function mapResponseMessages(response) {
    let messages = []
    if (response.data.infoMessages) {
      messages = response.data.infoMessages
    } else if (response.data.errorCode) {
      messages = [{ errorCode: response.data.errorCode }]
    }
    return messages
  }

  /**
   * emitResponseMessages emits all messages coming from an api response
   * If a message doesn't have an errorLevel, it is set to 'INFO' by default.
   * @param {APIMessage[]} messages
   * @param {string} level
   */

  //messages, level = NOTIFICATION.INFO, payload = {}
  function emitResponseMessages(opts = { level: NOTIFICATION.INFO }) {
    const { messages, level, payload } = opts

    if (messages === undefined || messages.length === 0) {
      return
    }

    messages.forEach(message => {
      events$.emit(EVENT_OVP.ERROR, {
        source: SOURCE.BACKEND,
        key: message.errorCode,
        type: message.errorLevel || level,
        personId: message.errorMessage,
        payload,
      })
    })
  }

  return {
    buildURL,
    getBaseUrl,
    setup,
    doDelete,
    doGet,
    doPost,
    doPut,
  }
}
