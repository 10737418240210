<template>
  <Teleport :to="modalRoot">
    <div
      :id="$props.id"
      ref="modalContainerRef"
      class="dvp-modal-container"
      :role="$props.isAlert ? 'alertdialog' : 'dialog'"
      aria-hidden="true"
      :aria-labelledby="titleId"
    >
      <template v-if="isOpen">
        <div
          :data-a11y-dialog-hide="$props.isAlert ? undefined : true"
          class="dvp-modal-overlay"
        />
        <div
          class="dvp-modal-wrapper"
          :class="{ 'the-grid': $props.size !== 'maxi' }"
        >
          <div
            ref="modalRef"
            role="document"
            class="dvp-modal appearance-surface"
            :class="classes"
          >
            <div class="dvp-modal__top">
              <basic-close-button
                v-if="$props.closeable"
                aria-label="Close Modal"
                :size="closeButtonSize"
                @click="close"
              />
            </div>

            <basic-scrolling-with-gradient
              v-if="isOpen"
              :has-gradient="$props.hasGradient"
            >
              <div :class="{ 'the-grid': $props.size === 'maxi' }">
                <div
                  v-if="$props.modalTitle || slots.title"
                  class="dvp-modal__title"
                >
                  <template v-if="$props.modalTitle">
                    <span
                      :id="titleId"
                      :class="{ h3: $props.size !== 'mini', 'title-1': $props.size === 'mini' }"
                      v-html="$props.modalTitle"
                    />
                  </template>
                  <template v-else>
                    <div
                      :id="titleId"
                      :class="{ h3: $props.size !== 'mini', 'title-1': $props.size === 'mini' }"
                    >
                      <slot name="title" />
                    </div>
                  </template>
                </div>
                <div class="dvp-modal__body body-2">
                  <slot />
                </div>
              </div>
            </basic-scrolling-with-gradient>
            <div
              v-if="modalActions"
              class="dvp-modal__footer"
              :class="{ 'the-grid': $props.size === 'maxi' }"
            >
              <div
                v-if="$props.type !== 'raw'"
                class="dvp-modal__actions appearance-positive"
              >
                <div
                  v-if="$props.type === 'transactional'"
                  class="transactional"
                >
                  <slot name="transactional">
                    <basic-button
                      :data-track-as="$props.primaryTrackAs"
                      :data-track-text="transactionalPrimaryLabel"
                      :label="transactionalPrimaryLabel"
                      @click="$emit('click-primary')"
                    />
                    <basic-button
                      hierarchy="secondary"
                      :data-track-as="$props.secondaryTrackAs"
                      :data-track-text="transactionalSecondaryLabel"
                      :label="transactionalSecondaryLabel"
                      @click="$emit('click-secondary')"
                    />
                  </slot>
                </div>

                <div
                  v-else-if="$props.type === 'acknowledgement'"
                  class="acknowledgement"
                >
                  <slot name="acknowledgement">
                    <basic-button
                      :label="acknowledgementLabel"
                      :data-track-as="$props.primaryTrackAs"
                      :data-track-text="acknowledgementLabel"
                      @click="$emit('click-primary')"
                    />
                  </slot>
                </div>

                <div
                  v-else-if="$props.type === 'progress'"
                  class="progress"
                >
                  <slot name="progress">
                    <div class="progress__navigation">
                      <basic-button
                        v-if="$props.primaryVisible"
                        :data-track-as="$props.primaryTrackAs"
                        :data-track-text="progressNextLabel"
                        :label="progressNextLabel"
                        @click="$emit('click-primary')"
                      />
                      <basic-button
                        v-if="$props.secondaryVisible"
                        hierarchy="secondary"
                        :data-track-as="$props.secondaryTrackAs"
                        :data-track-text="progressBackLabel"
                        :label="progressBackLabel"
                        @click="$emit('click-secondary')"
                      />
                    </div>
                    <basic-button
                      hierarchy="tertiary"
                      :label="progressCancelLabel"
                      @click="$emit('click-tertiary')"
                    />
                  </slot>
                </div>

                <div
                  v-else-if="$props.type === 'passive'"
                  class="passive"
                >
                  <slot name="passive">
                    <basic-button
                      hierarchy="primary"
                      :label="$t('form.close')"
                      @click="close"
                    />
                  </slot>
                </div>
              </div>

              <div
                v-else
                ref="modalFooterRef"
                class="dvp-modal__actions appearance-positive raw"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </Teleport>
</template>

<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, useSlots } from 'vue'

import A11yDialog from 'a11y-dialog'

import events$ from '@/services/Events'

import useI18n from '@/hooks/useI18n'

import BasicButton from '@/components/Basic/Button'
import BasicCloseButton from '@/components/Basic/CloseButton'
import BasicScrollingWithGradient from '@/components/Basic/ScrollingWithGradient'

import { EVENT_MODAL } from '@/config/events'

// HOOKS
const { t } = useI18n()
const slots = useSlots()

// INIT
const animationDuration = {
  timeout: 300,
  css: '300ms',
}
const emit = defineEmits(['click-primary', 'click-secondary', 'click-tertiary', 'close'])

const props = defineProps({
  cancelLabel: {
    type: String,
    default: '',
  },

  closeable: {
    type: Boolean,
    default: true,
  },

  hasGradient: {
    type: Boolean,
    default: true,
  },

  // Used for modal instantation and tracking
  id: {
    type: String,
    required: true,
  },

  isAlert: {
    type: Boolean,
    default: false,
  },

  primaryLabel: {
    type: String,
    default: null,
  },

  primaryTrackAs: {
    type: String,
    default: null,
  },

  primaryVisible: {
    type: Boolean,
    default: true,
  },

  secondaryLabel: {
    type: String,
    default: null,
  },

  secondaryTrackAs: {
    type: String,
    default: null,
  },

  secondaryVisible: {
    type: Boolean,
    default: true,
  },

  tertiaryLabel: {
    type: String,
    default: null,
  },

  size: {
    validator(value) {
      return ['mini', 'midi', 'maxi'].includes(value)
    },
    default: 'midi',
  },

  modalActions: {
    type: Boolean,
    default: true,
  },

  modalRoot: {
    type: String,
    default: 'body',
  },

  modalTitle: {
    type: String,
    default: null,
  },

  type: {
    /*
     *  passive:            no CTA
     *  transactional:      validate decisions, requires action to be completed and closed
     *  acknowledgement:    system requires the user to acknowledge the presented information
     *  progress:           navigate via next and back
     *  raw:
     */
    validator(value) {
      return ['passive', 'transactional', 'acknowledgement', 'progress', 'raw'].includes(value)
    },
    default: 'passive',
  },
})
// DATA
const modalContainerRef = ref(null)
const modalRef = ref(null)
const isOpen = ref(false)
const modalFooterRef = ref()

let a11yDialog

// COMPUTED
const classes = computed(() => {
  return {
    [`dvp-modal--${props.size}`]: true,
    [`dvp-modal--${props.type}`]: true,
  }
})

const closeButtonSize = computed(() => {
  return props.size === 'maxi' ? 'lg' : 'sm'
})

const titleId = computed(() => {
  return `${props.id}-title`
})

const acknowledgementLabel = computed(() => {
  return props.primaryLabel || 'Accept' // TODO: CONTENT
})

const progressBackLabel = computed(() => {
  return props.secondaryLabel || t('form.back')
})

const progressCancelLabel = computed(() => {
  return props.tertiaryLabel || t('form.cancel')
})

const progressNextLabel = computed(() => {
  return props.primaryLabel || t('form.next')
})

const transactionalPrimaryLabel = computed(() => {
  return props.primaryLabel || t('form.save')
})

const transactionalSecondaryLabel = computed(() => {
  return props.secondaryLabel || t('form.cancel')
})

// METHODS
function close() {
  a11yDialog.hide()
}

function disableScrolling() {
  const x = window.scrollX
  const y = window.scrollY

  window.onscroll = function () {
    window.scrollTo(x, y)
  }
}

function instantiateModal() {
  a11yDialog = new A11yDialog(modalContainerRef.value)

  // Set up event listeners for the new instance
  a11yDialog.on('show', handleModalShow)
  a11yDialog.on('hide', handleModalHide)
}

function handleModalShow() {
  disableScrolling()

  events$.emit(EVENT_MODAL.OPEN, { modalName: props.id })
  isOpen.value = true
}

function handleModalHide() {
  // Re-Enable background scrolling
  window.onscroll = null

  // Inform listeners (e.g. dropdown) to cleanup before modal is closed
  events$.emit(EVENT_MODAL.BEFORE_CLOSING, props.id)

  // Set closing css class
  modalContainerRef.value?.classList.add('dvp-modal-container--closing')

  // Await closing after event, to give listeners time to cleanup and await closing animation
  setTimeout(() => {
    modalContainerRef.value?.classList.remove('dvp-modal-container--closing')
    // Inform listeners (e.g. tracking) that modal is now closed
    events$.emit(EVENT_MODAL.CLOSE, { modalName: props.id })
    emit('close')
    isOpen.value = false
  }, animationDuration.timeout)
}

async function open() {
  if (a11yDialog === undefined) {
    await nextTick()
  }
  a11yDialog.show()
}

// LIFECYCLE HOOKS
onMounted(async () => {
  await nextTick()
  instantiateModal()
})

onBeforeUnmount(() => {
  // Re-Enable background scrolling, e.g. when user navigates back via browser-back
  window.onscroll = null
})

defineExpose({
  close,
  isOpen,
  open,

  modalFooterRef,
})
</script>

<style scoped>
@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.dvp-modal-container,
.dvp-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.dvp-modal-container {
  z-index: var(--dvp-stack-level-modal);
}

.dvp-modal-container--closing .dvp-modal-overlay,
.dvp-modal-container--closing .dvp-modal-wrapper {
  animation-name: fade-out;
  animation-duration: v-bind('animationDuration.css');
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
}

.dvp-modal-container:not(.dvp-modal-container--closing)[aria-hidden='true'] {
  display: none;
}

.dvp-modal-overlay {
  background-color: var(--scrim);
  backdrop-filter: blur(2px);
}

.dvp-modal-wrapper {
  position: relative;
  height: 100%;
  align-items: center;
}

.dvp-modal-overlay,
.dvp-modal-wrapper {
  animation-name: fade-in;
  animation-duration: v-bind('animationDuration.css');
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
}

.dvp-modal-wrapper:not(.the-grid) {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.dvp-modal {
  &--mini {
    --modal-border-radius: var(--fixed-border-radius-fix-03);

    grid-column: 1 / -1;
    max-height: 48dvh;
  }

  &--midi {
    --modal-border-radius: var(--fixed-border-radius-fix-03);

    grid-column: 1 / -1;
    max-height: 72dvh;
  }

  &--maxi {
    --modal-border-radius: var(--border-radius-res-l) var(--border-radius-res-l) var(--fixed-border-radius-fix-00)
      var(--fixed-border-radius-fix-00);

    /* grid-column: 1 / -1; */
    display: inline-block;
    width: 100%;
    max-height: 96dvh;
  }

  display: flex;
  flex-direction: column;
  z-index: var(--dvp-stack-level-popup-menu);
  background-color: var(--surface);
  box-shadow: var(--elevation-level-3);
  border-radius: var(--modal-border-radius);
  /* padding: var(--dotcom-responsive-gutter-responsive); */

  &__top {
    display: flex;
    justify-content: flex-end;
    padding: var(--dotcom-responsive-gutter-responsive) var(--dotcom-responsive-gutter-responsive)
      var(--dotcom-responsive-spacing-res-xs);
  }

  &__actions {
    padding: var(--fixed-spacing-fix-02) var(--dotcom-responsive-gutter-responsive)
      var(--dotcom-responsive-gutter-responsive);

    .transactional,
    .passive {
      display: flex;
      flex-direction: column;
      gap: var(--fixed-spacing-fix-02);
    }

    .acknowledgement {
      display: flex;
      justify-content: center;
    }

    .progress {
      display: flex;
      flex-direction: column;
      gap: var(--fixed-spacing-fix-02);

      &__navigation {
        display: flex;
        flex-direction: column;
        gap: var(--fixed-spacing-fix-02);
      }
    }

    &.raw {
      display: flex;
      flex-direction: column;
      gap: var(--fixed-spacing-fix-02);
      justify-content: space-between;
    }
  }
}

.dvp-modal--maxi:deep(.scrollable) {
  padding: 0;
}

.dvp-modal__title {
  margin: 0 0 var(--dotcom-responsive-spacing-res-xxs) 0;
}

.dvp-modal--mini .dvp-modal__title {
  margin: 0 0 var(--fixed-spacing-fix-02) 0;
}

.dvp-modal--maxi .dvp-modal__title,
.dvp-modal--maxi .dvp-modal__body {
  grid-column: 1 / -1;
}

.dvp-modal__footer.the-grid > * {
  grid-column: 1 / -1;
}

.dvp-modal__footer.the-grid .dvp-modal__actions {
  padding: var(--fixed-spacing-fix-02) 0 var(--dotcom-responsive-gutter-responsive);
}

@media (--v-medium) {
  .dvp-modal {
    &--mini {
      width: 320px;
      margin: 0 auto;
    }

    &__actions {
      .passive {
        flex-direction: row;
        justify-content: flex-end;
      }

      .transactional,
      &.raw {
        flex-direction: row-reverse;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: var(--fixed-spacing-fix-06);
      }
    }
  }
}

@media (--v-large) {
  .dvp-modal {
    &--midi {
      grid-column: 3 / 11;
    }

    &__actions {
      .acknowledgement {
        justify-content: flex-end;
      }

      .progress {
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: var(--fixed-spacing-fix-06);

        &__navigation {
          flex-direction: row-reverse;
          gap: var(--fixed-spacing-fix-06);
        }
      }
    }
  }
}

@media (--v-wide) {
  .dvp-modal {
    &--mini {
      width: auto;
      margin: 0;
      grid-column: 5 / 9;
    }
  }
}
</style>
