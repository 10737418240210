import { createApp } from 'vue'

import TheIcon from '@/components/Container/TheIcon'
import TheScreen from '@/components/Container/TheScreen'
import TheSection from '@/components/Container/TheSection'

import events$ from '@/services/Events'
import Tracking from '@/plugins/Tracking'
import i18n from '@/plugins/i18n'

import useBasket from '@/hooks/useBasket'
import usePerson from '@/hooks/usePerson'
import useSnackbar from '@/hooks/useSnackbar'

import clickOutside from '@/directives/ClickOutside'
import stickToElement from '@/directives/StickToElement'
import tooltip from '@/directives/Tooltip'

import { EVENT_OVP } from '@/config/events'

// HOOKS
const { updateLanguage } = useBasket()
const { getPersonFullName } = usePerson()
const { addNotification } = useSnackbar()

let isInitialized = false
// eslint-disable-next-line no-undef
const npmVersion = __NPM_VERSION__

function appInit(app) {
  if (!isInitialized) {
    console.info(
      '%c ⚑ sani%ctas DVP',
      'font-weight:bold;color:#00765A;',
      'font-weight:bold;color:#5BAC26;',
      `v${npmVersion}`
    )
    updateLanguage({})

    events$.on(EVENT_OVP.ERROR, message => {
      addNotification({
        key: message.key,
        type: message.type,
        payload: message.payload,
        personId: message.personId || null,
        personFullName: getPersonFullName(message.personId),
      })
    })
    isInitialized = true
  }
  return app
}

function setup(app) {
  app.provide('version$', npmVersion)
  app.provide('appName$', app._component.__name)

  // add global components
  app.directive('click-outside', clickOutside)
  app.directive('stick-to-element', stickToElement)
  app.directive('tooltip', tooltip)
  app.component('TheIcon', TheIcon)
  app.component('TheScreen', TheScreen)
  app.component('TheSection', TheSection)

  app.use(i18n)

  if (!isInitialized) {
    app.use(Tracking)
  }

  return appInit(app)
}

export { createApp, setup }
