<template>
  <!-- prettier-ignore -->
  <button
    class="button"
    tabindex="0"
    :class="classes"
    :href="$props.href"
    :type="$props.type"
    @click="event => $emit('click', event)"
  >
    <div class="button__inner">
      <slot name="leading"></slot>
      <slot>
        <span
          v-if="$props.label"
          v-text="$props.label"
        />
      </slot>

      <template v-if="isLoading">
        <the-icon
          art="duo"
          class="fa-spin"
          name="loader"
        />
      </template>

      <slot
        v-else
        name="trailing"
      />
    </div>
  </button>
</template>

<script setup>
import { computed } from 'vue'

import TheIcon from '@/components/Container/TheIcon'

import { loading$ } from '@/services'

// INIT
defineEmits(['click'])
const props = defineProps({
  hierarchy: {
    validator(value) {
      return ['primary', 'secondary', 'tertiary'].includes(value)
    },
    default: 'primary',
  },

  href: {
    type: String,
    default: undefined,
  },

  label: {
    type: String,
    default: undefined,
  },

  size: {
    validator(value) {
      return ['sm', 'lg'].includes(value)
    },
    default: 'lg',
  },

  type: {
    type: String,
    default: 'button',
  },
})

// COMPUTED
const classes = computed(() => {
  return {
    [`button--${props.hierarchy}`]: true,
    [`button--${props.size}`]: true,
  }
})

const isLoading = computed(() => {
  if (props.hierarchy !== 'primary') return
  const state = loading$.getState()

  return state.active.value && !state.options.blocking
})
</script>

<style>
:root,
.appearance-positive {
  --dvp-btn-primary-c-background: var(--Interaction-States-enabled-default);
  --dvp-btn-primary-c-text: var(--Interaction-States-label-default);
  --dvp-btn-primary-c-border: transparent;
  --dvp-btn-primary-c-hovered-background: var(--Interaction-States-hovered-default-a);
  --dvp-btn-primary-c-hovered-text: var(--Interaction-States-label-default);
  --dvp-btn-primary-c-hovered-border: var(--Interaction-States-hovered-default-a);
  --dvp-btn-primary-c-active-background: var(--Interaction-States-pressed-default-a);
  --dvp-btn-primary-c-active-text: var(--Interaction-States-label-default);
  --dvp-btn-primary-c-active-border: transparent;
  --dvp-btn-primary-c-disabled-background: var(--Interaction-States-disabled-default);
  --dvp-btn-primary-c-disabled-text: var(--Interaction-States-disabled-default-label);
  --dvp-btn-primary-c-disabled-border: transparent;

  --dvp-btn-secondary-c-background: transparent;
  --dvp-btn-secondary-c-text: var(--Interaction-States-enabled-default);
  --dvp-btn-secondary-c-border: var(--Interaction-States-enabled-default);
  --dvp-btn-secondary-c-hovered-background: transparent;
  --dvp-btn-secondary-c-hovered-text: var(--Interaction-States-hovered-default-a);
  --dvp-btn-secondary-c-hovered-border: var(--Interaction-States-hovered-default-a);
  --dvp-btn-secondary-c-active-background: var(--Interaction-States-pressed-default-b);
  --dvp-btn-secondary-c-active-text: var(--Interaction-States-pressed-default-a);
  --dvp-btn-secondary-c-active-border: var(--Interaction-States-pressed-default-a);
  --dvp-btn-secondary-c-disabled-background: var(--Interaction-States-disabled-default);
  --dvp-btn-secondary-c-disabled-text: var(--Interaction-States-disabled-default-label);
  --dvp-btn-secondary-c-disabled-border: transparent;

  --dvp-btn-tertiary-c-background: transparent;
  --dvp-btn-tertiary-c-text: var(--Interaction-States-enabled-default);
  --dvp-btn-tertiary-c-border: transparent;
  --dvp-btn-tertiary-c-hovered-background: var(--Interaction-States-hovered-default-b);
  --dvp-btn-tertiary-c-hovered-text: var(--Interaction-States-hovered-default-a);
  --dvp-btn-tertiary-c-hovered-border: var(--Interaction-States-hovered-default-b);
  --dvp-btn-tertiary-c-active-background: var(--Interaction-States-pressed-default-c);
  --dvp-btn-tertiary-c-active-text: var(--Interaction-States-pressed-default-a);
  --dvp-btn-tertiary-c-active-border: transparent;
  --dvp-btn-tertiary-c-disabled-background: var(--Interaction-States-disabled-default);
  --dvp-btn-tertiary-c-disabled-text: var(--Interaction-States-disabled-default-label);
  --dvp-btn-tertiary-c-disabled-border: transparent;

  --dvp-btn-c-outline: var(--on-surface);
}

.appearance-negative {
  --dvp-btn-primary-c-background: var(--Interaction-States-enabled-inverse);
  --dvp-btn-primary-c-text: var(--Interaction-States-label-inverse-a);
  --dvp-btn-primary-c-border: transparent;
  --dvp-btn-primary-c-hovered-background: var(--Interaction-States-hovered-inverse-a);
  --dvp-btn-primary-c-hovered-text: var(--Interaction-States-label-inverse-a);
  --dvp-btn-primary-c-hovered-border: var(--Interaction-States-hovered-inverse-a);
  --dvp-btn-primary-c-active-background: var(--Interaction-States-pressed-inverse-a);
  --dvp-btn-primary-c-active-text: var(--Interaction-States-label-inverse-a);
  --dvp-btn-primary-c-active-border: transparent;
  --dvp-btn-primary-c-disabled-background: var(--Interaction-States-disabled-inverse);
  --dvp-btn-primary-c-disabled-text: var(--Interaction-States-disabled-inverse-label);
  --dvp-btn-primary-c-disabled-border: transparent;

  --dvp-btn-secondary-c-background: transparent;
  --dvp-btn-secondary-c-text: var(--Interaction-States-enabled-inverse);
  --dvp-btn-secondary-c-border: var(--Interaction-States-enabled-inverse);
  --dvp-btn-secondary-c-hovered-background: transparent;
  --dvp-btn-secondary-c-hovered-text: var(--Interaction-States-label-inverse-b);
  --dvp-btn-secondary-c-hovered-border: var(--Interaction-States-label-inverse-b);
  --dvp-btn-secondary-c-active-background: var(--Interaction-States-pressed-inverse-b);
  --dvp-btn-secondary-c-active-text: var(--Interaction-States-label-inverse-c);
  --dvp-btn-secondary-c-active-border: var(--Interaction-States-label-inverse-c);
  --dvp-btn-secondary-c-disabled-background: var(--Interaction-States-disabled-inverse);
  --dvp-btn-secondary-c-disabled-text: var(--Interaction-States-disabled-inverse-label);
  --dvp-btn-secondary-c-disabled-border: transparent;

  --dvp-btn-tertiary-c-background: transparent;
  --dvp-btn-tertiary-c-text: var(--Interaction-States-enabled-inverse);
  --dvp-btn-tertiary-c-border: transparent;
  --dvp-btn-tertiary-c-hovered-background: var(--Interaction-States-hovered-inverse-b);
  --dvp-btn-tertiary-c-hovered-text: var(--Interaction-States-label-inverse-b);
  --dvp-btn-tertiary-c-hovered-border: var(--Interaction-States-hovered-inverse-b);
  --dvp-btn-tertiary-c-active-background: var(--Interaction-States-pressed-inverse-c);
  --dvp-btn-tertiary-c-active-text: var(--Interaction-States-label-inverse-c);
  --dvp-btn-tertiary-c-active-border: transparent;
  --dvp-btn-tertiary-c-disabled-background: var(--Interaction-States-disabled-inverse);
  --dvp-btn-tertiary-c-disabled-text: var(--Interaction-States-disabled-inverse-label);
  --dvp-btn-tertiary-c-disabled-border: transparent;

  --dvp-btn-c-outline: var(--on-inverse-surface-variant);
}

.appearance-extended {
  --dvp-btn-primary-c-background: var(--Interaction-States-enabled-extended);
  --dvp-btn-primary-c-text: var(--Interaction-States-label-extended);
  --dvp-btn-primary-c-border: transparent;
  --dvp-btn-primary-c-hovered-background: var(--Interaction-States-hovered-extended-a);
  --dvp-btn-primary-c-hovered-text: var(--Interaction-States-label-extended);
  --dvp-btn-primary-c-hovered-border: var(--Interaction-States-hovered-extended-a);
  --dvp-btn-primary-c-active-background: var(--Interaction-States-pressed-extended-a);
  --dvp-btn-primary-c-active-text: var(--Interaction-States-label-extended);
  --dvp-btn-primary-c-active-border: transparent;
  --dvp-btn-primary-c-disabled-background: var(--Interaction-States-disabled-extended);
  --dvp-btn-primary-c-disabled-text: var(--Interaction-States-disabled-extended-label);
  --dvp-btn-primary-c-disabled-border: transparent;

  --dvp-btn-secondary-c-background: transparent;
  --dvp-btn-secondary-c-text: var(--Interaction-States-enabled-extended);
  --dvp-btn-secondary-c-border: var(--Interaction-States-enabled-extended);
  --dvp-btn-secondary-c-hovered-background: transparent;
  --dvp-btn-secondary-c-hovered-text: var(--Interaction-States-hovered-extended-a);
  --dvp-btn-secondary-c-hovered-border: var(--Interaction-States-hovered-extended-a);
  --dvp-btn-secondary-c-active-background: var(--Interaction-States-pressed-extended-b);
  --dvp-btn-secondary-c-active-text: var(--Interaction-States-pressed-extended-a);
  --dvp-btn-secondary-c-active-border: var(--Interaction-States-pressed-extended-a);
  --dvp-btn-secondary-c-disabled-background: var(--Interaction-States-disabled-extended);
  --dvp-btn-secondary-c-disabled-text: var(--Interaction-States-disabled-extended-label);
  --dvp-btn-secondary-c-disabled-border: transparent;

  --dvp-btn-tertiary-c-background: transparent;
  --dvp-btn-tertiary-c-text: var(--Interaction-States-enabled-extended);
  --dvp-btn-tertiary-c-border: transparent;
  --dvp-btn-tertiary-c-hovered-background: var(--Interaction-States-hovered-extended-b);
  --dvp-btn-tertiary-c-hovered-text: var(--Interaction-States-hovered-extended-a);
  --dvp-btn-tertiary-c-hovered-border: var(--Interaction-States-hovered-extended-b);
  --dvp-btn-tertiary-c-active-background: var(--Interaction-States-pressed-extended-c);
  --dvp-btn-tertiary-c-active-text: var(--Interaction-States-pressed-extended-a);
  --dvp-btn-tertiary-c-active-border: var(--Interaction-States-pressed-extended-c);
  --dvp-btn-tertiary-c-disabled-background: var(--Interaction-States-disabled-extended);
  --dvp-btn-tertiary-c-disabled-text: var(--Interaction-States-disabled-extended-label);
  --dvp-btn-tertiary-c-disabled-border: transparent;

  --dvp-btn-c-outline: var(--on-surface);
}
</style>

<style scoped>
.button {
  padding: 0;
  transition: all 0.15s ease-in;
  border: 1px solid transparent;
  border-radius: var(--fixed-border-radius-fix-full);
  cursor: pointer;
  font-weight: var(--font-weight-bold);
  width: 100%;
  min-width: 144px;

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--fixed-spacing-fix-02);
    height: 100%;
    padding: 0 var(--fixed-spacing-fix-06);
    border: 1px solid transparent;
    border-radius: var(--fixed-border-radius-fix-full);
    transition: border-color 0.15s ease-in;
    white-space: nowrap;
  }

  &:focus-visible {
    outline: 2px solid var(--dvp-btn-c-outline);
    outline-offset: var(--fixed-spacing-fix-01);
  }

  &[disabled] {
    text-decoration: line-through;
    pointer-events: none;
  }

  &--lg {
    font-size: var(--font-size-bold-md);
    letter-spacing: var(--letter-spacing-bold-md);
    line-height: var(--line-height-bold-md);
    height: var(--fixed-spacing-fix-12);

    .button__inner {
      /*min-width: calc(9 * var(--font-size-bold-md));*/
    }
  }

  &--sm {
    font-size: var(--font-size-bold-sm);
    letter-spacing: var(--letter-spacing-bold-sm);
    line-height: var(--line-height-bold-sm);
    height: var(--fixed-spacing-fix-08);

    .button__inner {
      /*min-width: calc(9 * var(--font-size-bold-sm));*/
    }
  }

  &--primary {
    background-color: var(--dvp-btn-primary-c-background);
    border-color: var(--dvp-btn-primary-c-border);
    color: var(--dvp-btn-primary-c-text);

    &:hover {
      background-color: var(--dvp-btn-primary-c-hovered-background);
      border-color: var(--dvp-btn-primary-c-hovered-border);
      color: var(--dvp-btn-primary-c-hovered-text);

      .button__inner {
        border-color: var(--dvp-btn-primary-c-hovered-border);
      }
    }

    &:active {
      background-color: var(--dvp-btn-primary-c-active-background);
      border-color: var(--dvp-btn-primary-c-active-border);
      color: var(--dvp-btn-primary-c-active-text);

      .button__inner {
        border-color: transparent;
      }
    }

    &[disabled] {
      background-color: var(--dvp-btn-primary-c-disabled-background);
      border-color: var(--dvp-btn-primary-c-disabled-border);
      color: var(--dvp-btn-primary-c-disabled-text);
    }
  }

  &--secondary {
    background-color: var(--dvp-btn-secondary-c-background);
    border-color: var(--dvp-btn-secondary-c-border);
    color: var(--dvp-btn-secondary-c-text);

    &:hover {
      background-color: var(--dvp-btn-secondary-c-hovered-background);
      border-color: var(--dvp-btn-secondary-c-hovered-border);
      color: var(--dvp-btn-secondary-c-hovered-text);

      .button__inner {
        border-color: var(--dvp-btn-secondary-c-hovered-border);
      }
    }

    &:active {
      background-color: var(--dvp-btn-secondary-c-active-background);
      border-color: var(--dvp-btn-secondary-c-active-border);
      color: var(--dvp-btn-secondary-c-active-text);

      .button__inner {
        border-color: transparent;
      }
    }

    &[disabled] {
      background-color: var(--dvp-btn-secondary-c-disabled-background);
      border-color: var(--dvp-btn-secondary-c-disabled-border);
      color: var(--dvp-btn-secondary-c-disabled-text);
    }
  }

  &--tertiary {
    background-color: var(--dvp-btn-tertiary-c-background);
    border-color: var(--dvp-btn-tertiary-c-border);
    color: var(--dvp-btn-tertiary-c-text);

    &:hover {
      background-color: var(--dvp-btn-tertiary-c-hovered-background);
      border-color: var(--dvp-btn-tertiary-c-hovered-border);
      color: var(--dvp-btn-tertiary-c-hovered-text);

      .button__inner {
        border-color: var(--dvp-btn-tertiary-c-hovered-border);
      }
    }

    &:active {
      background-color: var(--dvp-btn-tertiary-c-active-background);
      border-color: var(--dvp-btn-tertiary-c-active-border);
      color: var(--dvp-btn-tertiary-c-active-text);

      .button__inner {
        border-color: transparent;
      }
    }

    &[disabled] {
      background-color: var(--dvp-btn-tertiary-c-disabled-background);
      border-color: var(--dvp-btn-tertiary-c-disabled-border);
      color: var(--dvp-btn-tertiary-c-disabled-text);
    }
  }
}

@media (--v-medium) {
  .button {
    width: initial;
  }
}
</style>
